import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'shared/DatePicker';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Select from 'shared/Select';

class AgeField extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    onFieldChange: PropTypes.func.isRequired,
    onTypeChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    showDob: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      show_dob: this.props.showDob
    };
  }

  _toggleAgeDob = () => {
    const new_type = !this.state.show_dob;

    this.setState({
      show_dob: new_type
    });

    this.props.onTypeChange(new_type);
  };

  _handleAgeChange = (name, e) => {
    const data = {
      date_of_birth: null,
      [name]: parseInt(e.target.value, 10)
    };

    if (name === 'current_age') {
      data.nearest_age = parseInt(e.target.value, 10);
    }

    this.props.onFieldChange(data);
  };

  _handleDobChange = (name, value) => {
    const data = {
      current_age: null,
      nearest_age: null,
      date_of_birth: value
    };

    this.props.onFieldChange(data);
  };

  render() {
    const { data, disabled, invalid, required } = this.props;
    const { show_dob } = this.state;

    return show_dob ? (
      <Field action={{ label: 'Enter Age', onClick: this._toggleAgeDob }} label='Date of Birth'>
        <DatePicker
          disabled={disabled}
          invalid={invalid}
          onChange={this._handleDobChange.bind(null, 'date_of_birth')}
          required={required}
          value={data.date_of_birth ? `${new Date(data.date_of_birth)}` : null}
        />
      </Field>
    ) : (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Field label='Current/Nearest Age' style={{ width: '49%' }}>
          <Input invalid={this.props.invalid} onChange={this._handleAgeChange.bind(null, 'current_age')} readOnly={disabled} required={required} step='1' type='number' value={data.current_age} />
        </Field>
        <Field action={{ label: 'Enter DOB', onClick: this._toggleAgeDob }} label={<span />} style={{ width: '49%' }}>
          <Select
            onChange={this._handleAgeChange.bind(null, 'nearest_age')}
            options={[{ value: data.current_age || null }, { value: parseInt(data.current_age, 10) + 1 || null }]}
            readOnly={disabled}
            value={data.nearest_age}
          />
        </Field>
      </div>
    );
  }
}

export default AgeField;
