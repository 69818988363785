import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
`;

const Loader = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const ErrorOverlay = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ErrorMessage = styled.p`
  color: #fff;
  font-size: 18px;
  padding: 10px;
  background-color: #dc3545;
  border: 2px solid #dc3545;
  border-radius: 5px;
`;
const QuoteLink = styled.button`
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
  background-color: #007bff;
  border: 2px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s;

  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
`;
const MessageText = styled.p`
  color: #4281f5;
  font-size: 16px !important;
  gap: 20px;
`;

const CustomLoader = ({ horizonLinkError, message }) => {
  const handleQuoteLinkClick = () => {
    window.location.reload();
  };
  return (
    <div>
      {!horizonLinkError ? (
        <LoadingOverlay>
          <Loader />
          {message && <MessageText>{message}</MessageText>}
        </LoadingOverlay>
      ) : (
        <>
          <ErrorOverlay>
            <ErrorMessage>{message}</ErrorMessage>
            <QuoteLink onClick={handleQuoteLinkClick}>Go Back to the Quotes</QuoteLink>
          </ErrorOverlay>
        </>
      )}
    </div>
  );
};
CustomLoader.propTypes = {
  horizonLinkError: PropTypes.bool.isRequired,
  message: PropTypes.string
};

export default CustomLoader;
