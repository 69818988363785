import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'shared/Modal';

import { ThemeContext } from 'shared/ThemeContext';

import { FontSizes, FontWeights } from 'constants/Clementine';

class ButtonMenuModal extends React.Component {
  static propTypes = {
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired
      })
    ).isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      hover: null
    };
  }

  _toggleHover = hover => {
    this.setState({ hover });
  };

  render() {
    const { buttons, onClose, title } = this.props;
    const { hover } = this.state;
    const styles = this.styles();

    return (
      <Modal maxWidth={800} onClose={onClose} title={title}>
        <div style={styles.content}>
          <div style={styles.wrapper}>
            {buttons.map(button => {
              const { onClick, id, disabled_msg, label, icon } = button;
              const is_hovering = button.id === hover && button.onClick;
              const is_disabled = !button.onClick;

              return (
                <div
                  key={id}
                  onClick={onClick}
                  onMouseEnter={this._toggleHover.bind(null, id)}
                  onMouseLeave={this._toggleHover.bind(null, null)}
                  style={Object.assign({}, styles.box_button, is_hovering && styles.box_button_hover)}
                >
                  {is_disabled && disabled_msg ? <div style={styles.disabled_msg}>{disabled_msg}</div> : null}
                  <i className={`mdi ${icon}`} style={Object.assign({}, styles.box_button_icon, is_disabled && styles.box_button_disabled)} />
                  <div style={Object.assign({}, styles.box_button_label, is_disabled && styles.box_button_disabled)}>{label}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'wrap',
        padding: '40px 20px'
      },
      box_button: {
        width: 120,
        height: 120,
        margin: '0 10px 20px',
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        border: '1px solid #ccc',
        borderRadius: 4,
        cursor: 'pointer',
        position: 'relative'
      },
      box_button_hover: {
        border: `2px solid ${this.context.color}`
      },
      box_button_icon: {
        fontSize: 40
      },
      box_button_label: {
        marginTop: 10,
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.MEDIUM
      },
      box_button_disabled: {
        opacity: 0.5,
        cursor: 'default'
      },
      disabled_msg: {
        fontSize: FontSizes.SMALL,
        fontWeight: FontWeights.MEDIUM,
        position: 'absolute',
        top: 10,
        left: -1,
        right: -1,
        padding: 4,
        background: this.context.color,
        color: '#fff',
        zIndex: 2
      }
    };
  };
}

ButtonMenuModal.contextType = ThemeContext;

export default ButtonMenuModal;
