import React from 'react';
import PropTypes from 'prop-types';

import { FontSizes, FontWeights } from 'constants/Clementine';

const Checklist = ({ disabled, onChange, options, selected, style }) => {
  const styles = {
    option: {
      padding: '4px 0',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    checkbox: {
      fontSize: 20,
      marginLeft: 10
    },
    label: {
      fontSize: FontSizes.REGULAR,
      fontWeight: FontWeights.REGULAR,
      lineHeight: '1.3em',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  };

  const _onChange = o => {
    const s = selected.slice();

    if (s.includes(o.value)) {
      s.splice(s.indexOf(o.value), 1);
    } else {
      s.push(o.value);
    }

    if (onChange) {
      onChange(s);
    }
  };

  return (
    <div style={style}>
      {options.map((o, i) => {
        const is_selected = selected.includes(o.value);
        const label = o.label || o.value;

        return (
          <div key={i} onClick={disabled ? null : _onChange.bind(null, o)} style={styles.option}>
            <div style={styles.label}>{label}</div>
            <input checked={is_selected} disabled={disabled} readOnly={true} style={styles.checkbox} type='checkbox' />
          </div>
        );
      })}
    </div>
  );
};

Checklist.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired
};

export default Checklist;
