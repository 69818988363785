import React from 'react';
import PropTypes from 'prop-types';

import { FontSizes, FontWeights } from 'constants/Clementine';

const Checkbox = ({ label, onChange, style, checked }) => {
  const styles = {
    option: {
      padding: '4px 0',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    checkbox: {
      fontSize: 20
    },
    label: {
      fontSize: FontSizes.REGULAR,
      fontWeight: FontWeights.REGULAR,
      lineHeight: '1.3em',
      width: '100%',
      marginLeft: 10,
      textTransform: 'none',
      letterSpacing: 0
    }
  };

  return (
    <label onClick={onChange} style={Object.assign({}, styles.option, style)}>
      <input checked={checked} onChange={onChange} style={styles.checkbox} type='checkbox' />
      <span style={styles.label}>{label}</span>
    </label>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  label: PropTypes.string,
  onChange: PropTypes.func
};

export default Checkbox;
