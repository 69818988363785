import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Colors, FontSizes } from 'constants/Clementine';

import { ThemeContext } from 'shared/ThemeContext';

const PowerSelect = props => {
  const context = React.useContext(ThemeContext);
  const styles = {
    select: {
      control: () =>
        Object.assign(
          {},
          {
            alignItems: 'center',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: props.invalid ? Colors.RED.hex : '#e5e5e5',
            borderRadius: 4,
            width: '100%',
            background: props.disabled ? '#f5f5f5' : '#fff',
            cursor: 'default',
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: FontSizes.REGULAR,
            justifyContent: 'space-between',
            minHeight: 40,
            position: 'relative',
            padding: '3px 0px'
          },
          props.style
        ),
      menu: provider =>
        Object.assign({}, provider, {
          borderRadius: 2,
          lineHeight: '1.6em',
          fontSize: FontSizes.REGULAR,
          zIndex: 2
        }),
      multiValue: provider =>
        Object.assign({}, provider, {
          backgroundColor: context.color || Colors.GREEN.hex,
          borderRadius: 3,
          padding: '5px 4px 5px 8px',
          display: 'flex',
          justifyContent: 'space-between',
          margin: '2px 4px 2px 0px'
        }),
      multiValueLabel: provider =>
        Object.assign({}, provider, {
          color: '#fff',
          fontSize: FontSizes.REGULAR,
          fontWeight: 700,
          paddingLeft: 0,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          margin: 0
        }),
      multiValueRemove: provider =>
        Object.assign({}, provider, {
          color: '#fff',
          paddingLeft: 2,
          paddingTop: 0,
          paddingRight: 2,
          paddingBottom: 0,
          marginLeft: 4,
          cursor: 'pointer'
        })
    },
    required: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: Colors.RED.hex
    }
  };
  const components = {
    IndicatorSeparator: null
  };

  if (!props.options) {
    components.DropdownIndicator = null;
  }

  return (
    <div className='ixn-power-select' style={{ position: 'relative' }}>
      {props.creatable ? (
        <CreatableSelect
          components={components}
          formatCreateLabel={props.formatCreateLabel || (inputValue => `Add ${inputValue}`)}
          isClearable={false}
          isDisabled={props.disabled}
          isMulti={props.isMulti}
          isValidNewOption={props.newOptionCheck}
          noOptionsMessage={() => props.noOptionsMessage}
          onChange={props.onChange}
          placeholder={props.placeholder}
          styles={styles.select}
          value={props.value}
        />
      ) : (
        <Select
          components={components}
          isClearable={false}
          isDisabled={props.disabled}
          isMulti={props.isMulti}
          onChange={props.onChange}
          options={props.options}
          placeholder={props.placeholder}
          styles={styles.select}
          value={props.value}
        />
      )}
      {props.required ? <span style={styles.required}>*</span> : null}
    </div>
  );
};

PowerSelect.propTypes = {
  creatable: PropTypes.bool,
  disabled: PropTypes.bool,
  formatCreateLabel: PropTypes.func,
  invalid: PropTypes.bool,
  isMulti: PropTypes.bool,
  newOptionCheck: PropTypes.func,
  noOptionsMessage: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object])
};

export default PowerSelect;
