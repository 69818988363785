import React from 'react';
import PropTypes from 'prop-types';

import { Colors, FontSizes, FontWeights } from 'constants/Clementine';

const FieldGroup = ({ action, children, label, style = {} }) => {
  const styles = {
    component: {
      marginBottom: 30
    },
    label: {
      fontSize: FontSizes.REGULAR,
      fontWeight: FontWeights.MEDIUM,
      color: Colors.GRAY.hex,
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'space-between'
    },
    content: {
      marginLeft: 10
    }
  };

  return (
    <div style={{ ...styles.component, ...style }}>
      <div style={styles.label}>
        {label}
        {action}
      </div>
      <div style={styles.content}>{children}</div>
    </div>
  );
};

FieldGroup.propTypes = {
  action: PropTypes.node,
  label: PropTypes.string.isRequired
};

export default FieldGroup;
