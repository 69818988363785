import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';

import { Colors, FontSizes } from 'constants/Clementine';

const Page = ({ button, children, imageUrl, style, title }) => (
  <div style={Object.assign({}, styles.component, style)}>
    <div style={styles.title}>{title}</div>
    <div style={styles.message}>{children}</div>
    {imageUrl && <img src={imageUrl} style={{ maxWidth: '100%' }} />}
    {button ? <Button {...button} /> : null}
  </div>
);

Page.propTypes = {
  button: PropTypes.object,
  imageUrl: PropTypes.string,
  title: PropTypes.string
};

const styles = {
  component: {
    textAlign: 'center',
    padding: '40px 0',
    color: Colors.GRAY.hex
  },
  title: {
    fontSize: FontSizes.XLARGE
  },
  message: {
    fontSize: FontSizes.LARGE,
    color: Colors.GRAY.hex,
    margin: '20px auto 40px',
    width: '90%',
    maxWidth: 600,
    lineHeight: '1.4em'
  }
};

export default Page;
