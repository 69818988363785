import React from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';

import { Colors, FontSizes, FontWeights } from 'constants/Clementine';

class Table extends React.Component {
  static propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    defaultSortDirection: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      sort_by_column: props.columns.find(c => c.default) || {},
      sort_direction: props.defaultSortDirection || 'asc'
    };
  }

  _handleColumnSort = column => {
    if (column.is_sortable) {
      this.setState({
        sort_by_column: column,
        sort_direction: this.state.sort_direction === 'asc' ? 'desc' : 'asc'
      });
    }
  };

  render() {
    const styles = this.styles();
    const { columns, data } = this.props;
    const { sort_by_column, sort_direction } = this.state;
    const sorted_data = _orderBy(data || [], sort_by_column.sort_by || sort_by_column.render || sort_by_column.id, sort_direction);

    return (
      <React.Fragment>
        <div style={styles.list_table_header}>
          <div style={styles.list_table_row}>
            {columns.map(column => (
              <div key={column.id} onClick={this._handleColumnSort.bind(null, column)} style={Object.assign({}, styles.list_table_header_column, column.style, { width: column.width })}>
                {column.label}
                {column.is_sortable && sort_by_column.id === column.id ? (
                  <i className={`mdi ${sort_direction === 'asc' ? 'mdi-chevron-up' : 'mdi-chevron-down'}`} style={{ fill: '#999', marginLeft: 5, fontSize: 14, position: 'absolute', right: 10 }} />
                ) : null}
              </div>
            ))}
          </div>
        </div>

        {sorted_data.map(item => (
          <div key={item.guid} style={styles.list_table_row}>
            {columns.map(column => {
              return (
                <div key={column.id} style={Object.assign({}, styles.list_table_column, column.style, { width: column.width })}>
                  {column.render ? column.render(item) : item[column.id]}
                </div>
              );
            })}
          </div>
        ))}
      </React.Fragment>
    );
  }

  styles = () => {
    return {
      list_table_header: {
        position: 'sticky',
        top: 0
      },
      list_table_header_column: {
        background: Colors.GRAY.hex,
        color: '#fff',
        borderRight: '1px solid #e5e5e5',
        padding: '10px 20px 10px 15px',
        textTransform: 'uppercase',
        fontWeight: FontWeights.MEDIUM,
        fontSize: FontSizes.SMALL,
        lineHeight: '1.2em',
        textAlign: 'left',
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        position: 'relative',
        cursor: 'pointer'
      },
      list_table_row: {
        display: 'flex'
      },
      list_table_column: {
        fontSize: FontSizes.REGULAR,
        padding: 15,
        lineHeight: '1.4em',
        display: 'flex',
        alignItems: 'center',
        borderRight: '1px solid #e5e5e5',
        borderBottom: '1px solid #e5e5e5',
        flexShrink: 0
      }
    };
  };
}

export default Table;
