import React from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'shared/ThemeContext';

import ZeroState from 'shared/ZeroState';

import { Colors, FontSizes, FontWeights } from 'constants/Clementine';

class CarrierForms extends React.Component {
  static propTypes = {
    forms: PropTypes.array,
    formTags: PropTypes.array,
    layout: PropTypes.string,
    onFormClick: PropTypes.func,
    selectedForms: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      hover_form_guid: null
    };
  }

  _handleFormHover = (form_guid = null) => {
    this.setState({
      hover_form_guid: form_guid
    });
  };

  _handleFormClick = form_guid => {
    let selected_forms = this.props.selectedForms;

    if (selected_forms.includes(form_guid)) {
      selected_forms = selected_forms.filter(f => f !== form_guid);
    } else {
      selected_forms.push(form_guid);
    }

    this.props.onFormClick(selected_forms);
  };

  render() {
    const styles = this.styles();
    const { forms, formTags, layout, selectedForms } = this.props;
    const { hover_form_guid } = this.state;
    const form_attributes = [
      {
        id: 'guid',
        label: '',
        render: ({ guid }) => {
          const is_selected = selectedForms.includes(guid);

          return <input checked={is_selected} onChange={this._handleFormClick.bind(null, guid)} style={styles.checkbox} type='checkbox' />;
        },
        style: { textAlign: 'center', verticalAlign: 'middle' },
        width: 50
      },
      {
        id: 'form_name',
        label: 'Name',
        render: form => (
          <a href={form.pdf_file.url} style={styles.link} target='_blank'>
            {form.form_name}
          </a>
        ),
        style: { fontWeight: FontWeights.MEDIUM },
        width: '25%'
      },
      {
        id: 'form_number',
        label: 'Number',
        style: { textAlign: 'center', justifyContent: 'center' },
        width: '15%'
      },
      {
        id: 'description',
        label: 'Description',
        style: { flexShrink: 1 },
        width: '100%'
      }
    ];

    if (formTags && formTags.length) {
      form_attributes.push({
        id: 'form_tag_ids',
        label: 'Categories',
        render: ({ form_tag_ids = [] }) => {
          const form_tag_names = form_tag_ids.map(form_tag_id => {
            const form_tag = formTags.find(ft => ft.id === form_tag_id) || {};

            return form_tag.name;
          });

          return form_tag_names.join(', ');
        },
        style: { fontSize: FontSizes.SMALL },
        width: '15%'
      });
    }

    if (forms.length) {
      if (layout === 'grid') {
        return (
          <div style={styles.grid_wrapper}>
            {forms.map(form => {
              const image = form.pdf_file && form.pdf_file.web_thumb ? form.pdf_file.web_thumb.url : '';
              const is_selected = selectedForms.includes(form.guid);
              const form_tag_ids = form.form_tag_ids || [];

              return (
                <div key={form.guid} onMouseEnter={this._handleFormHover.bind(null, form.guid)} onMouseLeave={this._handleFormHover} style={styles.form}>
                  <div style={styles.preview_label}>
                    <div style={styles.form_name}>
                      <a href={form.pdf_file.url} style={styles.link} target='_blank'>
                        {form.form_name}
                      </a>
                    </div>
                    <div style={styles.form_number}>{form.form_number || 'N/A'}</div>
                  </div>
                  <div onClick={this._handleFormClick.bind(null, form.guid)} style={Object.assign({ backgroundImage: 'url(' + image + ')' }, styles.preview)}>
                    <div style={styles.preview_content}>
                      {form_tag_ids.map(form_tag_id => {
                        const form_tag = formTags.find(ft => ft.id === form_tag_id) || {};

                        return (
                          <div key={form_tag_id} style={styles.form_tag}>
                            {form_tag.name}
                          </div>
                        );
                      })}
                    </div>

                    {hover_form_guid === form.guid || is_selected ? (
                      <div style={styles.hover}>
                        <i className='mdi mdi-checkbox-marked-circle-outline' style={Object.assign({}, styles.hover_check, is_selected ? styles.hover_checkActive : null)} />
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <React.Fragment>
            <div style={styles.list_table_header}>
              <div style={styles.list_table_row}>
                {form_attributes.map(attr => (
                  <div key={attr.id} style={Object.assign({}, styles.list_table_header_column, attr.style, { width: attr.width })}>
                    {attr.label}
                  </div>
                ))}
              </div>
            </div>

            {forms.map(form => (
              <div key={form.guid} style={styles.list_table_row}>
                {form_attributes.map(attr => {
                  return (
                    <div key={attr.id} style={Object.assign({}, styles.list_table_column, attr.style, { width: attr.width })}>
                      {attr.render ? attr.render(form) : form[attr.id]}
                    </div>
                  );
                })}
              </div>
            ))}
          </React.Fragment>
        );
      }
    } else {
      return <ZeroState icon='mdi-file-pdf' message='No forms found.' />;
    }
  }

  styles = () => {
    return {
      grid_wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '30px 15px'
      },
      list_table_header: {
        position: 'sticky',
        top: 0
      },
      list_table_header_column: {
        background: '#f1f1f1',
        borderRight: '1px solid #e5e5e5',
        padding: 15,
        textTransform: 'uppercase',
        fontWeight: FontWeights.MEDIUM,
        fontSize: FontSizes.SMALL,
        lineHeight: '1',
        textAlign: 'left',
        flexShrink: 0
      },
      list_table_row: {
        borderBottom: '1px solid #e5e5e5',
        display: 'flex'
      },
      list_table_column: {
        padding: 15,
        lineHeight: '1.4em',
        display: 'flex',
        alignItems: 'center',
        borderRight: '1px solid #e5e5e5',
        flexShrink: 0
      },
      form: {
        width: '30.33%',
        margin: '0 1.5% 3%',
        position: 'relative',
        border: '1px solid #e5e5e5',
        borderRadius: 4,
        overflow: 'hidden'
      },
      preview: {
        paddingTop: '130%',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '3px 3px 0 0',
        position: 'relative'
      },
      preview_content: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        padding: 10
      },
      form_tag: {
        fontSize: FontSizes.SMALL,
        padding: '4px 8px',
        borderRadius: 4,
        background: Colors.GRAY.hex,
        color: '#fff',
        fontWeight: FontWeights.MEDIUM,
        margin: '0px 2px',
        alignSelf: 'flex-end'
      },
      preview_label: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e5e5e5',
        padding: 10,
        fontSize: FontSizes.REGULAR
      },
      form_name: {
        fontWeight: FontWeights.BOLD,
        overflow: 'hidden',
        textOverflor: 'ellipsis',
        whiteSpace: 'nowrap',
        marginBottom: 4
      },
      hover: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.6)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
      },
      hover_check: {
        fontSize: 60,
        color: 'rgba(255,255,255,0.5)'
      },
      hover_checkActive: {
        color: Colors.GREEN.hex
      },
      btn_icon: {
        fontSize: 18,
        verticalAlign: 'top'
      },
      checkbox: {
        fontSize: 20,
        cursor: 'pointer'
      },
      link: {
        color: this.context.color,
        textDecoration: 'none',
        cursor: 'pointer'
      }
    };
  };
}

CarrierForms.contextType = ThemeContext;

export default CarrierForms;
